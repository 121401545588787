<template>
    <div>
        <b-row>
            <!-- What's your question? -->
            <b-col md="12">
                <b-form-group label="What's your question?" label-for="yourQuestion">
                    <b-form-input type="text" name="yourQuestion" v-model="prop_siteSettingArray.yourQuestion" />
                </b-form-group>
            </b-col>

            <!-- First Answer -->
            <b-col md="12">
                <b-form-group label="First Answer" label-for="firstAnswer">
                    <b-form-input type="text" name="firstAnswer" v-model="prop_siteSettingArray.firstAnswer" />
                </b-form-group>
            </b-col>

            <!-- Second Answer -->
            <b-col md="12">
                <b-form-group label="Second Answer" label-for="secondAnswer">
                    <b-form-input type="text" name="secondAnswer" v-model="prop_siteSettingArray.secondAnswer" />
                </b-form-group>
            </b-col>

            <!-- Enter link URL for second answer -->
            <b-col md="12">
                <b-form-group label="Enter link URL for second answer" label-for="secondButtonURL">
                    <b-form-input type="text" name="secondButtonURL" v-model="prop_siteSettingArray.secondButtonURL" placeholder="http://example.com" />
                </b-form-group>
            </b-col>

            <!-- Font family -->
            <b-col md="12">
                <b-form-group label="Font family" label-for="questionFontFamily">
                    <Select2 :options="questionFontFamily" name="questionFontFamily" v-model="prop_siteSettingArray.questionFontFamily" />
                </b-form-group>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { BRow, BCol, BFormInput, BFormGroup, BInputGroup } from 'bootstrap-vue'
    import captureOptions from "@/libs/capture-Options"

    export default {
        components: {
            BRow, BCol, BFormInput, BFormGroup, BInputGroup
        },
        props: {
            prop_siteSettingArray: {
                type: Object,
                default: () => { },
            }
        },
        computed: {
        },
        created() {
            if (_.isEmpty(this.prop_siteSettingArray.yourQuestion)) {
                this.prop_siteSettingArray.yourQuestion = 'First time here?';
            }
            if (_.isEmpty(this.prop_siteSettingArray.firstAnswer)) {
                this.prop_siteSettingArray.firstAnswer = 'Yes'
            }
            if (_.isEmpty(this.prop_siteSettingArray.secondAnswer)) {
                this.prop_siteSettingArray.secondAnswer = 'No';
            }
            if (_.isEmpty(this.prop_siteSettingArray.questionFontFamily)) {
                this.prop_siteSettingArray.questionFontFamily = 'Open Sans';
            }
        },
        setup() {
            const { questionFontFamily } = captureOptions();
            return {
                questionFontFamily
            }
        },
    }
</script>